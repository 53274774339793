import { Injectable } from "@angular/core";
import FingerprintJS from "@fingerprintjs/fingerprintjs";

@Injectable({
	providedIn: "root",
})
export class FingerprintService {
	private readonly STORAGE_KEY = "device_fingerprint";
	private fpPromise = FingerprintJS.load();

	// Generate random device id
	async getRandomDeviceId(): Promise<string> {
		const fp = await this.fpPromise;
		const result = await fp.get();
		const newId = result.visitorId;

		return newId;
	}

	// Generate random hostess device id
	async getRandomDeviceIdHostess(): Promise<string> {
		const fp = await this.fpPromise;
		const result = await fp.get();
		const newId = result.visitorId + "-" + Date.now();

		return newId;
	}

	// Get the current device id
	async getDeviceId(): Promise<string> {
		let storedId = localStorage.getItem(this.STORAGE_KEY);
		const hostessInfo = localStorage.getItem("hostessInfo") ? JSON.parse(localStorage.getItem("hostessInfo")) : null;

		// Check if the value is already stored
		if (storedId) {
			return storedId;
		}

		// Generate a new fingerprint and store it in the local storage
		const fp = await this.fpPromise;
		const result = await fp.get();
		const newId = hostessInfo ? result.visitorId + "-" + Date.now() : result.visitorId;

		localStorage.setItem(this.STORAGE_KEY, newId);
		return newId;
	}
}
