import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from "@angular/router";
import { Observable, of } from "rxjs";
import { map, catchError } from "rxjs/operators";
import { RestApiService } from "src/app/core/services/rest-api.service";

@Injectable({
	providedIn: "root",
})
export class HostessAuthGuard {
	constructor(
		private router: Router,
		private restApiService: RestApiService
	) {}

	canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> {
		// Check if the application is PWA or not
		let isStandalone = false;
		if (window.matchMedia("(display-mode: standalone)").matches || (window.navigator as any).standalone) {
			isStandalone = true;
		}

		// Get the slug base on the url
		const campaignSlug = route.params["campaign-slug"];

		// Get hostess informations
		const hostessInfo = localStorage.getItem("hostessInfo");

		// Make an API call to this campaign
		return this.restApiService.get(`campaigns/${campaignSlug}`).pipe(
			map((response) => {
				// Get the campaign ageVerificationMethod
				const ageVerificationMethod = response.data?.ageVerificationMethod;

				// Check if the app is PWA or not
				if (isStandalone) {
					if (hostessInfo !== null) {
						// Hostess is logged in
						return true;
					} else {
						// Redirect to hostess route for login,
						return this.router.createUrlTree(["/las/hostess"]);
					}
				} else {
					// Check if the campaign ageVerificationMethod is set to "hav"
					if (ageVerificationMethod === "hav") {
						if (hostessInfo !== null) {
							// Hostess is logged in
							return true;
						} else {
							// Redirect to hostess route for login,
							return this.router.createUrlTree(["/las/hostess"]);
						}
					}
				}

				// For non-hostess mode, allow access
				return true;
			}),
			catchError((error) => {
				console.error("Error fetching campaign data:", error);
				return of(this.router.createUrlTree(["/error"])); // Redirect to an error page
			})
		);
	}
}
