<main [ngStyle]="ngStyle">
	<!-- <main> -->
	<!-- Header section -->
	@if (gameChooserHeaderOpt) {
		<header class="header-section">
			<h2 class="header-title">
				<img class="header-img" [src]="backendURL + campaignHeader?.logo.url" alt="" />
				<div class="header-text" [innerHTML]="campaignHeader?.text"></div>
			</h2>
		</header>
	}

	<!-- Content section -->
	<section class="content-container" id="content-container">
		<div class="main-content">
			<div class="content-header-title" [ngStyle]="{ 'margin-top': !(campaignHeader?.logo && gameChooserHeaderOpt) ? '40px' : '0' }" [innerHTML]="gameChooserScreen?.richText"></div>

			<div class="main-slider">
				<div class="slider-section">
					<ngx-slick-carousel
						class="slider-list"
						#slickModal="slick-carousel"
						[config]="slideConfig"
						(init)="slickInit($event)"
						(breakpoint)="breakpoint($event)"
						(afterChange)="afterChange($event)"
						(beforeChange)="beforeChange($event)"
					>
						@for (product of productChooser; track $index) {
							<div ngxSlickItem class="item-img-section" [style.--campaign-color]="getRgbColor()">
								<div class="item-container">
									<img class="item-img" [src]="backendURL + product.product.img.url" alt="" width="100%" />
								</div>
								<p class="item-text">{{ product.product.name }}</p>
							</div>
						}
					</ngx-slick-carousel>
				</div>
			</div>

			<div class="events-section">
				<h2 class="events-title" [innerHTML]="headLineText"></h2>

				<ul class="event-list">
					<li
						*ngFor="let item of gameChooserScreen?.items; let i = index"
						(click)="!isDateEqualOrAboveCurrent(item.releaseDate) ? navigateTo(item.url, item.releaseDate) : null"
						[ngStyle]="{ cursor: !isDateEqualOrAboveCurrent(item.releaseDate) ? 'pointer' : 'not-allowed' }"
						class="event-item unlocked-event"
						[style.background-image]="'url(' + backendURL + item.bgImg.url + ')'"
					>
						<div class="baseBackground" [class.blurBackground]="isDateEqualOrAboveCurrent(item.releaseDate)"></div>

						<div class="item-title-content">
							<h3 class="item-title">{{ item.text }}</h3>
							<img class="item-icon" [src]="isDateEqualOrAboveCurrent(item.releaseDate) ? lockedIcon : unlockedIcon" />
						</div>

						<div class="item-date-content">
							<span class="item-date">{{ convertDateFormat(item.releaseDate) }}</span>
							<span>{{ isDateEqualOrAboveCurrent(item.releaseDate) }}</span>
						</div>
					</li>
				</ul>
			</div>
		</div>
	</section>

	<!-- Footer section -->
	<footer class="footer-section">
		<div class="footer-btn-next">
			<button
				[ngStyle]="{
					color: colorCTA,
					'background-color': bgColorCTA,
					border: borderCTA
				}"
				class="btn btn-danger footer-btn"
				(click)="onFooterBtnClick()"
			>
				{{ footerBtnText }}
			</button>
		</div>

		@if (gameChooserFooterOpt) {
			<div class="footer-text-section">
				@if (footerText) {
					<div class="footer-text" [innerHTML]="footerText | safeHtml"></div>
				}
			</div>
		}
	</footer>
</main>
