<main [ngStyle]="ngStyle">
	<!-- <main> -->
	<!-- Header section -->
	@if (tuturialHeaderOpt) {
		<header class="header-section">
			<h2 class="header-title">
				<img class="header-img" [src]="backendURL + campaignHeader?.logo.url" alt="" />
				<div class="header-text" [innerHTML]="campaignHeader?.text"></div>
			</h2>
		</header>
	}

	<!-- Content section -->
	<section class="content-container" id="content-container">
		<div class="main-content" style="padding-inline: 20px">
			<div class="content-header-title" [innerHTML]="tutorialData?.richText"></div>

			<div class="tutorial-section">
				@for (item of tutorialData?.items; track $index) {
					<div class="option-item">
						@if (item.img?.url) {
							<div class="img-container">
								<img src="{{ backendURL + item.img?.url }}" [alt]="" />
							</div>
						}

						<h3 class="option-text" [style.width]="item.img?.url == null ? '100% !important' : null">{{ item.text }}</h3>
					</div>
				}
			</div>
		</div>
	</section>

	<!-- Footer section -->
	<footer class="footer-section">
		<div class="footer-btn-next">
			<button
				[ngStyle]="{
					color: colorCTA,
					'background-color': bgColorCTA,
					border: borderCTA
				}"
				class="btn btn-danger footer-btn"
				(click)="onFooterBtnClick()"
			>
				{{ footerBtnText }}
			</button>
		</div>

		@if (tuturialFooterOpt) {
			<div class="footer-text-section">
				@if (footerText) {
					<div class="footer-text" [innerHTML]="footerText | safeHtml"></div>
				}
			</div>
		}
	</footer>
</main>
