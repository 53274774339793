<!-- <main> -->
<main [ngStyle]="ngStyle">
	<!-- Header section -->
	@if (contentHeaderOtp) {
		<header class="header-section">
			<h2 class="header-title">
				<img class="header-img" [src]="backendURL + campaignHeader?.logo.url" alt="" />
				<div class="header-text" [innerHTML]="campaignHeader?.text"></div>
			</h2>
		</header>
	}

	<!-- Content section -->
	<section class="content-container" id="content-container" (click)="!contentCTAOpt && onFooterBtnClick()">
		<div class="main-content">
			<div class="dynamic-content-wrapper">
				@if (contentData) {
					@switch (contentDataType) {
						@case ("text") {
							<div class="text-content">
								<p [innerHTML]="contentTextdata"></p>
							</div>
						}

						@case ("image") {
							@if (this.contentData.img?.url) {
								<div class="image-content">
									<div class="image-content-image" [style.backgroundImage]="'url(' + contentImgdata + ')'"></div>
								</div>
							}
						}

						@case ("video") {
							<div class="video-content-container">
								<div class="video-content">
									<video #videoPlayer loop muted playsinline (loadeddata)="onVideoLoaded()">
										<source [src]="contentVideodata" type="video/mp4" />
										Your browser does not support the video tag.
									</video>
								</div>
							</div>
						}
						@case ("none") {}
					}
				}
			</div>
		</div>
	</section>

	<!-- Footer section -->
	<footer class="footer-section">
		@if (contentCTAOpt) {
			<div class="footer-btn-next">
				<button class="btn btn-danger footer-btn" (click)="onFooterBtnClick()">{{ footerBtnText }}</button>
			</div>
		}

		@if (contentFooterOtp) {
			<div class="footer-text-section">
				@if (footerText) {
					<div class="footer-text" [innerHTML]="footerText | safeHtml"></div>
				}
			</div>
		}
	</footer>
</main>
