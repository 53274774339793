import { Injectable } from "@angular/core";
import { CanMatch, Route, Router, UrlSegment, UrlTree } from "@angular/router";
import { Observable, of, switchMap } from "rxjs";
import { AuthService } from "./auth.service";

@Injectable({
	providedIn: "root",
})
export class AuthGuard implements CanMatch {
	constructor(
		private authService: AuthService,
		private router: Router
	) {}

	canMatch(): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
		return this.authService.check().pipe(
			switchMap((authenticated) => {
				if (!authenticated) {
					return of(this.router.createUrlTree(["/account/sign-in"]));
				}
				return of(true);
			})
		);
	}
}
