import { Component, ElementRef, OnInit, ViewChild, ViewEncapsulation } from "@angular/core";
import { FormGroup } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { Subject, takeUntil } from "rxjs";
import { LasService } from "../core/services/las.service";
import { environment } from "src/environments/environment";

import { DeviceUUID } from "device-uuid";
import { RestApiService } from "src/app/core/services/rest-api.service";
import { RoutingOrderService } from "src/app/core/services/routing-order.service";
import { NgbModal, NgbModalRef } from "@ng-bootstrap/ng-bootstrap";
import { PDFDocumentProxy, PDFProgressData } from "ng2-pdf-viewer";
import { ThemeService } from "../core/services/theme.service";
import { TokenService } from "../core/services/token.service";
import { FingerprintService } from "src/app/core/services/finger-print.service";
import { RouteOrderGuard } from "../core/guards/route.guard";
import { JourneyOfflineService } from "../core/services/journey.offline.service";

@Component({
	selector: "app-policy",
	templateUrl: "./policy.component.html",
	styleUrl: "./policy.component.scss",
	encapsulation: ViewEncapsulation.None,
})
export class PolicyComponent implements OnInit {
	unsubscriber: Subject<any> = new Subject<any>();
	backendURL = environment.backendURL;
	ngStyle: any;
	form: FormGroup;
	policyCheckedState: { [key: number]: boolean } = {};
	isOnline = true;
	isStandalone = false;
	policyData: any = null;
	footerBtnText: string;
	campaignHeader: any;
	footerText: string;
	policyHeaderOpt: boolean;
	policyFooterOpt: boolean;

	uuid: string = "";

	// LAS Log
	lasData: any;
	stepName: string;
	policyLogVerfication: any;
	verificationMethod: string;

	// Modal
	@ViewChild("modalEdit", { static: true }) modalEdit: ElementRef;
	modalEditRef: NgbModalRef;
	editModalSize: string;
	modalContent: string;
	modalPDFLoading: boolean = false;

	// CTA Theme
	colorCTA: string = "#fff";
	bgColorCTA: string = "teal";
	borderCTA: string = "none";

	backgroundImageUrl: string;

	// Hostess
	hostessInfo: any;

	// Campaign CTA Text
	ctaNextText: string;

	constructor(
		private router: Router,
		private activatedRoute: ActivatedRoute,
		private lasService: LasService,
		private restApiService: RestApiService,
		private routingOrderService: RoutingOrderService,
		private modalService: NgbModal,
		private themeService: ThemeService,
		private tokenService: TokenService,
		private fingerprintService: FingerprintService,
		private routeGuard: RouteOrderGuard,
		private journeyOfflineService: JourneyOfflineService
	) {}

	// --------------------------------------------------------------------------------------------
	// @ Lifecycle hooks
	// --------------------------------------------------------------------------------------------
	ngOnInit() {
		this.routeGuard.markRouteAsVisited("policy");

		// Get hostess info
		this.hostessInfo = localStorage.getItem("hostessInfo") ? JSON.parse(localStorage.getItem("hostessInfo")) : null;

		// Add step to the journey offline
		this.journeyOfflineService.addStep("policy", false);

		this.initializeLogVerfication();

		this.fingerprintService.getDeviceId().then((uuid) => {
			this.uuid = uuid;
		});

		this.lasService.campaignSubject.pipe(takeUntil(this.unsubscriber)).subscribe((data: any) => {
			if (data != null) {
				this.campaignHeader = data.campaign?.theme.header;
				this.footerText = data.campaign?.theme.footer;

				this.ctaNextText = data.campaign.theme.cta?.ctaNextText;

				this.verificationMethod = data.campaign?.ageVerificationMethod;

				this.policyData = data.campaign.steps.filter((e) => e.__component === "screen.policy")[0];
				this.policyHeaderOpt = this.policyData.isHeaderEnabled;
				this.policyFooterOpt = this.policyData.isFooterEnabled;

				// Log step
				const stepLebelFormated = this.removeSpacesBetweenWords(this.policyData.stepLabel);
				if (!this.policyLogVerfication[stepLebelFormated]) {
					this.stepName = this.policyData.stepLabel;
					this.lasService.lasSubject.pipe(takeUntil(this.unsubscriber)).subscribe((lasData: any) => {
						if (lasData !== null) {
							this.lasData = lasData;
							this.logStep(this.lasData.journey, this.stepName);
							this.updateLogVerfication(this.removeSpacesBetweenWords(this.stepName), "true");
						}
					});
				}

				this.policyData.items.forEach((item) => {
					this.policyCheckedState[item.id] = false;
				});

				// Set CTA text
				this.footerBtnText = this.policyData?.ctaText;
				// if (this.policyData?.ctaText) {
				// 	this.footerBtnText = this.policyData?.ctaText;
				// } else {
				// 	this.footerBtnText = this.ctaNextText;
				// }

				// Set theme --------------------
				const { ngStyle, backgroundImageUrl } = this.themeService.getThemeStyles(data.campaign?.theme, this.policyData);
				this.ngStyle = ngStyle;
				this.backgroundImageUrl = backgroundImageUrl;
			}
		});
	}

	ngOnDestroy(): void {
		this.unsubscriber.next(null);
		this.unsubscriber.complete();
	}

	// --------------------------------------------------------------------------------------------
	// @ Events
	// --------------------------------------------------------------------------------------------
	onFooterBtnClick() {
		// Get the current path
		const currentPath = this.activatedRoute.snapshot.routeConfig.path;

		// Get the next route
		const nextRoute = this.routingOrderService.getNextRoute(currentPath);

		// Check verification method
		if (this.verificationMethod === "hav") {
			// Navigate to the next route
			this.routingOrderService.navigateToNext(currentPath);
		} else if (this.verificationMethod === "yoti") {
			this.lasService.lasSubject.pipe(takeUntil(this.unsubscriber)).subscribe((lasData: any) => {
				if (lasData !== null) {
					const { isAgeVerified } = lasData;

					if (isAgeVerified) {
						// Navigate to the next route
						this.routingOrderService.navigateToNext(currentPath);
					} else {
						this.activatedRoute.queryParamMap.subscribe((params) => {
							// Get verification result & token from the url
							const yotiVerification = params.get("yotiVerification");
							const token = params.get("token");

							// Check if yotiVerification & token is not found
							if (!(yotiVerification && token)) {
								try {
									// Generate a secure token
									const token = this.tokenService.generateToken(this.uuid);

									// Get the redirectUrl of the next route
									const redirectUrl = `${this.router.url}`.replace(currentPath, nextRoute);

									// Construct the coded redirect URL
									const encodedRedirectUrl = encodeURIComponent(`${window.location.origin}${redirectUrl}`);

									// Construct the age verification app URL with parameters
									const ageVerificationUrl = `${environment.ageVerificationAppUrl}?redirectToUrl=${encodedRedirectUrl}&token=${token}`;

									// Call set yoti token
									this.restApiService
										.put("lases/set-token", this.uuid, {
											yotiToken: token,
										})
										.pipe(takeUntil(this.unsubscriber))
										.subscribe((response) => {
											// Redirect to the age verification app
											window.location.href = ageVerificationUrl;
										});
								} catch (error) {
									console.error("Error generating token:", error);
									// Handle the error appropriately
								}
							}
						});
					}
				}
			});
		}
	}

	onPolicyClick(e: MouseEvent) {
		e.preventDefault();
		e.stopPropagation();

		console.log("Policy link clicked");
	}

	handlePDFModel(e: MouseEvent, url: string) {
		e.preventDefault();
		e.stopPropagation();

		this.modalContent = url;
		this.modalEditRef = this.modalService.open(this.modalEdit, { size: this.editModalSize, centered: true });

		this.modalPDFLoading = true;
	}

	handlePDFProgress(pdf: PDFDocumentProxy) {
		// Check if the pdf is loaded
		if (pdf) {
			setTimeout(() => {
				this.modalPDFLoading = false;
			}, 400);
		}
	}

	// --------------------------------------------------------------------------------------------
	// @ Function
	// --------------------------------------------------------------------------------------------
	checkAllPoliciesAccepted(): boolean {
		return Object.values(this.policyCheckedState).every((value) => value === true);
	}

	logStep(journeyId: number, stepLabel: string) {
		this.lasService
			.logStep(journeyId, stepLabel)
			.pipe(takeUntil(this.unsubscriber))
			.subscribe({
				next: (response) => {
					console.log("Journey policy created:", response);
				},
				error: (error) => {
					console.error("Error creating journey:", error);
				},
			});
	}

	// Initialize LogVerfication & Update the component log
	private initializeLogVerfication() {
		const storedState = localStorage.getItem("logVerfication");
		this.policyLogVerfication = storedState ? JSON.parse(storedState) : {};
	}

	private updateLogVerfication(key: string, value: any) {
		this.policyLogVerfication[key] = value;
		localStorage.setItem("logVerfication", JSON.stringify(this.policyLogVerfication));
	}

	// Remove spaces from the text
	removeSpacesBetweenWords(text: any) {
		return text.replace(/(\S)\s+(\S)/g, "$1$2");
	}
}
