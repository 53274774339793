<main [ngStyle]="ngStyle">
	<!-- <main> -->
	<!-- Header section -->
	@if (QAHeaderOpt) {
		<header class="header-section">
			<h2 class="header-title">
				<img class="header-img" [src]="backendURL + campaignHeader?.logo.url" alt="" />
				<div class="header-text" [innerHTML]="campaignHeader?.text"></div>
			</h2>
		</header>
	}

	<!-- Content section -->
	<section class="content-container" id="content-container">
		<div class="main-content">
			<div class="qa-container">
				@if (!showResult) {
					<h2 class="question">{{ currentQuestion()?.question }}</h2>
				}

				@if (!showResult) {
					<div class="responses">
						@for (response of currentQuestion()?.answers; track response.answer) {
							<div class="response" (click)="selectAnswer(response)" [class.selected]="selectedAnswer === response">
								<img class="fullscreen-image" [src]="backendURL + response.bgImg.url" alt="" />
								<div class="overlay"></div>
								@if (selectedAnswer !== response) {
									<span class="response-text">{{ response.answer }}</span>
								} @else {
									<span class="response-icon">
										<img src="/assets/las/images/check-circle.svg" width="32" height="32" alt="" />
										<!-- <i class="bi bi-check-circle-fill"></i> -->
									</span>
								}
							</div>
						}
					</div>
				} @else {
					<div class="correct-answer">
						<h3>{{ isCorrectAnswer() ? correctAnswerText : wrongAnswerText }}</h3>
						<div class="response">
							<img class="fullscreen-image" [src]="backendURL + correctAnswer().bgImg.url" alt="" />
							<div class="overlay"></div>
							<span class="response-text">{{ correctAnswer().answer }}</span>
						</div>
					</div>
				}
			</div>
		</div>
	</section>

	<!-- Footer section -->
	<footer class="footer-section">
		<div class="footer-btn-next">
			<button
				[disabled]="!selectedAnswer"
				[ngStyle]="{
					color: colorCTA,
					'background-color': bgColorCTA,
					border: borderCTA
				}"
				class="btn btn-danger footer-btn"
				(click)="onFooterBtnClick()"
			>
				{{ footerBtnText }}
			</button>
		</div>

		@if (QAFooterOpt) {
			<div class="footer-text-section">
				@if (footerText) {
					<div class="footer-text" [innerHTML]="footerText | safeHtml"></div>
				}
			</div>
		}
	</footer>
</main>
