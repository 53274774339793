import { Injectable } from "@angular/core";
import { RestApiService } from "./rest-api.service";

// campaign-distribution.service.ts
@Injectable({
	providedIn: "root",
})
export class CampaignDistributionService {
	constructor(private restApiService: RestApiService) {}

	async getCampaignDistribution(campaignId: string, hostessId?: number) {
		const consumptionMap = await this.getConsumptionMap(campaignId, hostessId);
		return { consumptionMap };
	}

	async getConsumptionMap(campaignId: string, hostessId?: number) {
		const consumptionMap = new Map<
			number,
			{
				total: number;
				byDate: Map<string, number>;
			}
		>();

		try {
			// Build filters based on whether hostessId is provided
			let filters = `filters[campaign][id]=${campaignId}`;
			if (hostessId) {
				filters += `&filters[hostess][id]=${hostessId}`;
			}

			const winnings = await this.restApiService
				.get("winnings", {
					filters: filters,
					populate: `populate[product]=true&populate[hostess]=true`,
					pagination: { pageSize: 999999 },
					sort: { field: "createdAt", order: "asc" },
				})
				.toPromise();
			winnings.data.forEach((winning) => {
				const productId = winning.product.id;
				const date = new Date(winning.createdAt).toDateString();

				if (!consumptionMap.has(productId)) {
					consumptionMap.set(productId, {
						total: 0,
						byDate: new Map<string, number>(),
					});
				}

				const productStats = consumptionMap.get(productId)!;
				productStats.total += 1;
				productStats.byDate.set(date, (productStats.byDate.get(date) || 0) + 1);
			});

			return consumptionMap;
		} catch (error) {
			console.error("Error fetching consumption data:", error);
			return new Map();
		}
	}

	getTodayAvailableProducts(campaign: any, consumptionMap: Map<number, any>, hostessId?: number): Map<number, number> {
		const today = new Date().toDateString();
		const availableProducts = new Map<number, number>();

		campaign.winnings.forEach((winning) => {
			const productId = winning.product.id;
			const consumption = consumptionMap.get(productId) || { total: 0, byDate: new Map() };
			const todayConsumed = consumption.byDate.get(today) || 0;

			// Calculate today's planned quantity using the same distribution logic
			let accumulatedQty = winning.initialQty;
			const remainingDays = this.getRemainingDays(campaign.startDate, campaign.endDate);
			// If hostess ID is provided, adjust allocation based on hostess count
			let plannedToday = Math.ceil(accumulatedQty / remainingDays);
			if (hostessId && campaign.hostesses.length) {
				// Distribute daily allocation among hostesses
				plannedToday = Math.ceil(plannedToday / campaign.hostesses.length);
			}

			// Available = Planned - Consumed
			const available = Math.max(0, plannedToday - todayConsumed);
			availableProducts.set(productId, plannedToday);
		});

		return availableProducts;
	}

	private getRemainingDays(startDate: Date, endDate: Date): number {
		const start = new Date(startDate);
		const end = new Date(endDate);
		const today = new Date();

		// Set time to start of day for accurate comparison
		start.setHours(0, 0, 0, 0);
		end.setHours(0, 0, 0, 0);
		today.setHours(0, 0, 0, 0);

		// If campaign hasn't started yet
		if (today < start) {
			return Math.floor((end.getTime() - start.getTime()) / (1000 * 60 * 60 * 24)) + 1;
		}

		// If campaign has started, count from today
		return Math.floor((end.getTime() - today.getTime()) / (1000 * 60 * 60 * 24)) + 1;
	}
}
