import { NgModule } from "@angular/core";
import { ExtraOptions, PreloadAllModules, RouterModule, Routes } from "@angular/router";
import { AdminLayoutComponent } from "./layouts/layout.component";
import { LasLayoutComponent } from "./layouts/las-layout/las-layout.component";
import { HostessAuthComponent } from "./apps/las/hostess-auth/hostess-auth.component";
import { SigninLayoutComponent } from "./layouts/signin-layout/signin-layout.component";
import { AuthGuard } from "./core/auth/auth.guard";

const routerConfig: ExtraOptions = {
	preloadingStrategy: PreloadAllModules,
	scrollPositionRestoration: "enabled",
	enableViewTransitions: true,
};

const routes: Routes = [
	{ path: "", redirectTo: "admin", pathMatch: "full" },
	{
		path: "las/hostess",
		component: HostessAuthComponent,
	},
	{
		path: "las/:campaign-slug",
		component: LasLayoutComponent,
		children: [
			{
				path: "",
				loadChildren: () => import("./apps/las/las.module").then((m) => m.LasModule),
			},
		],
	},
	{
		path: "account",
		component: SigninLayoutComponent,
		children: [
			{
				path: "",
				loadChildren: () => import("./apps/admin/account/account.module").then((m) => m.AccountModule),
			},
		],
	},
	{
		path: "admin",
		component: AdminLayoutComponent,
		canMatch: [AuthGuard],
		children: [
			{
				path: "",
				loadChildren: () => import("./apps/admin/admin.module").then((m) => m.AdminModule),
			},
		],
	},
];

@NgModule({
	imports: [RouterModule.forRoot(routes, routerConfig)],
	exports: [RouterModule],
})
export class AppRoutingModule {}
