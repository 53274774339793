import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { catchError, Observable, of, ReplaySubject, switchMap, tap, throwError } from "rxjs";
import { environment } from "src/environments/environment";

@Injectable({
	providedIn: "root",
})
export class AuthService {
	private authenticated: boolean = false;
	private apiURL = environment.apiURL;
	userSubject: ReplaySubject<any> = new ReplaySubject<any>(1);

	constructor(private httpClient: HttpClient) {}

	set accessToken(token: string) {
		localStorage.setItem("accessToken", token);
	}

	get accessToken(): string {
		return localStorage.getItem("accessToken") ?? "";
	}

	set user(value: any) {
		this.userSubject.next(value);
	}

	get user(): Observable<any> {
		return this.httpClient.get<any>(`${this.apiURL}/users/me?populate[0]=role`).pipe(
			tap((user) => {
				this.user = user;
			})
		);
	}

	signIn(credentials: { identifier: string; password: string }): Observable<any> {
		return this.httpClient.post(`${this.apiURL}/auth/local`, { data: credentials }).pipe(
			switchMap((response: any) => {
				this.authenticated = true;
				this.accessToken = response.jwt;
				this.user = response.user;
				return of(response);
			})
		);
	}

	forgotPassword(email: string): Observable<any> {
		return this.httpClient.post(`${this.apiURL}/auth/forgot-password`, email);
	}

	resetPassword(password: string): Observable<any> {
		return this.httpClient.post(`${this.apiURL}/auth/reset-password`, password);
	}

	signOut(): Observable<any> {
		localStorage.removeItem("accessToken");
		this.authenticated = false;
		return of(true);
	}

	signInUsingToken(): Observable<any> {
		return this.httpClient.post(`${this.apiURL}/auth/sign-in-with-token`, { accessToken: this.accessToken }).pipe(
			catchError(() => of(false)),
			switchMap((response: any) => {
				if (response.accessToken) {
					this.accessToken = response.accessToken;
				}
				this.authenticated = true;
				this.user = response.user;
				return of(true);
			})
		);
	}

	check(): Observable<boolean> {
		if (this.authenticated) {
			return of(true);
		}
		if (!this.accessToken) {
			return of(false);
		}
		return this.signInUsingToken();
	}
}
