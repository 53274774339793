import { Injectable } from "@angular/core";
import { HttpClient, HttpErrorResponse } from "@angular/common/http";
import { BehaviorSubject, Observable, catchError, throwError, tap } from "rxjs";
import { environment } from "src/environments/environment";

@Injectable({ providedIn: "root" })
export class LasService {
	private apiURL = environment.apiURL;
	campaignSubject = new BehaviorSubject<any>(null);
	lasSubject = new BehaviorSubject<any>(null);

	constructor(private httpClient: HttpClient) {}

	getStepPayload(data, stepName) {
		return data.campaign.steps.filter((e) => e.__component === stepName)[0];
	}

	checkAndRegisterLASByUUID(uuid: string, hostess?: number, campaignId?: any): Observable<any> {
		return this.httpClient.get<any>(`${this.apiURL}/lases/${uuid}/${campaignId}`).pipe(
			catchError((error) => {
				if (error.status === 404) {
					// Check if we have hostess ID or not
					if (hostess) {
						return this.registerLASByUUID(uuid, hostess, campaignId);
					} else {
						return this.registerLASByUUID(uuid, null, campaignId);
					}
				}
				return throwError(() => new Error(error));
			})
		);
	}

	registerLASByUUID(uuid: string, hostess?: number, campaignId?: any): Observable<any> {
		// Check if we have hostess ID or not
		if (hostess) {
			return this.httpClient.post(`${this.apiURL}/lases`, { data: { uuid, hostess, campaign: campaignId } }).pipe(catchError(this.handleError));
		} else {
			return this.httpClient.post(`${this.apiURL}/lases`, { data: { uuid, campaign: campaignId } }).pipe(catchError(this.handleError));
		}
	}

	logStep(journeyId: number, stepLabel: string, additionalData = {}): Observable<any> {
		return this.httpClient
			.post(`${this.apiURL}/steps`, {
				data: {
					journey: journeyId,
					stepLabel: stepLabel,
					...additionalData,
				},
			})
			.pipe(catchError(this.handleError));
	}

	handleError(errorResponse: HttpErrorResponse) {
		console.error("Error occurred:", errorResponse);
		return throwError(() => new Error(errorResponse.message));
	}

	getLasData(): Observable<any> {
		return this.lasSubject.asObservable();
	}
}
