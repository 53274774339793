<!-- Main -->
<main class="gradient-bg">
	<!-- Content section -->
	<section class="content-container" id="content-container">
		<div class="main-content">
			@if (isHostessLogin) {
				<button class="logout-button" (click)="handleLogout()">
					<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2">
						<path d="M9 21H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h4"></path>
						<polyline points="16 17 21 12 16 7"></polyline>
						<line x1="21" y1="12" x2="9" y2="12"></line>
					</svg>

					<span>Logout {{ hostessData?.user?.name }}</span>
				</button>
			}

			@if (!isHostessLogin) {
				<img src="/assets/admin/images/logo.png" class="content-header-img" alt="" />

				<div class="content-data-login">
					<h3>Login</h3>

					<form [formGroup]="form" (ngSubmit)="onFooterBtnClick()" class="input-wrapper">
						<div class="input-group-login">
							<div class="input-group">
								<span class="input-group-text bg-white border-0 input-span-1">
									<img class="input-icon" src="/assets/las/images/person.svg" alt="" />
								</span>
								<input formControlName="identifier" type="email" id="loginInputId" class="form-control border-0" placeholder="Entrer ton login" autocomplete="off" />
								<span class="input-group-text bg-white border-0 input-span-2"></span>
							</div>
						</div>

						<div class="input-group-password">
							<div class="input-group">
								<span class="input-group-text bg-white border-0 input-span-1">
									<img class="input-icon" src="/assets/las/images/key.svg" alt="" />
								</span>
								<input formControlName="password" type="password" id="passwordInputId" class="form-control border-0" placeholder="Entrer ton password" autocomplete="off" />
								<span class="input-group-text bg-white border-0 input-span-2"></span>
							</div>

							<span class="input-warning-text" [class.opacity]="inputWarningMessage !== ''">
								<img class="input-icon-warning" src="/assets/las/images/exclamation.svg" alt="" />
								<span>{{ inputWarningMessage }}</span>
							</span>
						</div>

						<button type="submit" class="btn-hostess">Se Connecter</button>
					</form>
				</div>
			} @else if (isHostessLogin && !isStandalone) {
				<div class="page-container">
					<div class="games-grid">
						@for (campaign of hostessCampaigns; track campaign.id) {
							<div class="games-item">
								<div class="game-card" (click)="setStartUrlWeb('/las/' + campaign?.slug)">
									<img [src]="backendURL + campaign?.banner?.url" class="game-image" alt="" />
									<div class="game-content">
										<h5 class="game-title">{{ campaign?.name }}</h5>
									</div>
								</div>
							</div>
						}
					</div>
				</div>
			} @else if (isHostessLogin && isStandalone) {
				<div class="page-container">
					<div class="games-grid">
						@for (campaign of hostessCampaigns; track campaign.id) {
							<div class="games-item">
								<div class="game-card" (click)="setStartUrl('/las/' + campaign?.slug)">
									<img [src]="backendURL + campaign?.banner?.url" class="game-image" alt="" />
									<div class="game-content">
										<h5 class="game-title">{{ campaign?.name }}</h5>
									</div>
								</div>
							</div>
						}
					</div>
				</div>
			}
		</div>
	</section>
</main>
