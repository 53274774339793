import { Component, OnInit } from "@angular/core";
import { SwUpdate, VersionReadyEvent } from "@angular/service-worker";
import { filter, map, Subject, Subscription, takeUntil } from "rxjs";
import { CacheAssetsService } from "./core/services/cache-assets.service";
import { NavigationEnd, Router } from "@angular/router";
import { ZoomPreventionService } from "./core/services/zoom-prevent.service";
import { LasService } from "./apps/las/core/services/las.service";
import { environment } from "src/environments/environment";
import { NgxUiLoaderService } from "ngx-ui-loader";
import { UrlService } from "./apps/las/core/services/url.service";

@Component({
	selector: "app-root",
	templateUrl: "./app.component.html",
	styleUrl: "./app.component.scss",
})
export class AppComponent implements OnInit {
	private subscription: Subscription;
	unsubscriber: Subject<any> = new Subject<any>();
	backendUrl = environment.backendURL;

	progress = 0;
	isOnline = true;
	modalVersion = false;
	modalPwaEvent: any;
	modalPwaPlatform?: string;
	isProgressComplete = "";
	progressCompleted = false;
	isStandalone = false;
	showOverlay = true;
	allData: any;
	startUrl: any = null;

	isDataChanged: boolean = false;

	// Auth Data
	isAuth: any = null;
	authData: any;

	constructor(
		private CacheAssetsService: CacheAssetsService,
		private router: Router,
		private zoomPreventionService: ZoomPreventionService,
		private lasService: LasService,
		private ngxLoader: NgxUiLoaderService,
		private urlService: UrlService
	) {}

	ngOnInit(): void {
		// Check if hostess is login and if get the hostess info
		this.isAuth = localStorage.getItem("hostessInfo");
		if (this.isAuth !== null) {
			this.authData = JSON.parse(this.isAuth);
		}

		// Check if the url have value, and if call the setStartUrl with new data
		this.subscription = this.urlService.urlChanged$.subscribe((url) => {
			this.setStartUrl(url);
		});

		this.updateOnlineStatus();
		this.checkStandaloneMode();
		this.startUrl = localStorage.getItem("startUrl");
		if (this.isStandalone === true) {
			this.router.navigateByUrl(this.startUrl);
		}

		if (this.isStandalone) {
			this.lasService.campaignSubject.pipe(takeUntil(this.unsubscriber)).subscribe((data: any) => {
				if (data != null) {
					this.allData = data.campaign;

					// Add wheelRichTextUrls object to the allData variable.
					const wheelData = data.campaign.steps.filter((e) => e.__component === "wheel.wheel")[0];
					const lostMessage = wheelData.lostMessage;
					this.allData["wheelRichTextUrls"] = this.extractUrls(lostMessage);

					const lastUpdate = new Date(this.allData.updatedAt).toLocaleString();
					const storedUpdate = localStorage.getItem("lastUpdate");
					console.log("storedUpdate 1 ", storedUpdate);
					if (storedUpdate != lastUpdate) {
						this.isDataChanged = true;
						console.log(this.isDataChanged);
						// reload data if data changed oin backend
						if (this.isDataChanged === true && storedUpdate !== null) {
							console.log("is data changed true");
							this.startPrefetch();
						}
						localStorage.setItem("lastUpdate", lastUpdate);
						console.log("The update date has changed:", lastUpdate);
						console.log("storedUpdate 2", storedUpdate);
					}
				}
			});
		}

		this.zoomPreventionService.preventZoom();
		this.isProgressComplete = localStorage.getItem("setupComplete");

		if (this.isProgressComplete) {
			this.progressCompleted = true;
		}

		window.addEventListener("online", this.updateOnlineStatus.bind(this));
		window.addEventListener("offline", this.updateOnlineStatus.bind(this));

		this.router.events.subscribe((event) => {
			if (event instanceof NavigationEnd) {
				document.getElementById("content-container")?.scrollTo(0, 0);
			}
		});

		// start frefetch of data if in pwa
		if (this.isStandalone && this.isOnline && this.progressCompleted === false && this.startUrl !== null) {
			this.startPrefetch();
		}

		// clear all cache in browzing mode
		if (this.isStandalone === false) {
			this.clearAllCaches();
		}

		if (this.isStandalone === true && !this.startUrl && this.isOnline && this.progressCompleted === false) {
			// redirect to hostess
			this.router.navigate(["/las/hostess"]);
		}
	}

	// function to clear all cached items
	clearAllCaches(): Promise<void> {
		return caches
			.keys()
			.then((cacheNames) => {
				return Promise.all(cacheNames.map((cacheName) => caches.delete(cacheName)));
			})
			.then(() => {
				console.log("All caches cleared");
			})
			.catch((error) => {
				console.error("Error clearing caches:", error);
			});
	}

	// Update start url in local stoefg
	setStartUrl(page: string) {
		localStorage.setItem("startUrl", page);
		this.updateManifestStartUrl(page);
	}

	// Update he starts url in ,anifest file from localstorage
	updateManifestStartUrl(page: string) {
		const manifestLink = document.querySelector('link[rel="manifest"]') as HTMLLinkElement;

		if (manifestLink) {
			fetch(manifestLink.href)
				.then((response) => response.json())
				.then((manifestData) => {
					manifestData.start_url = page;

					// Create a new Blob to update the manifest
					const newManifestBlob = new Blob([JSON.stringify(manifestData)], { type: "application/json" });
					const newManifestUrl = URL.createObjectURL(newManifestBlob);

					// Update the manifest link
					manifestLink.setAttribute("href", newManifestUrl);
				})
				.then(() => {
					document.location.reload();
				})
				.catch((error) => {
					console.error("Error fetching manifest:", error);
				});
		}
	}

	// Updates the online/offline status
	private updateOnlineStatus(): void {
		this.isOnline = window.navigator.onLine;
		console.info(`isOnline=[${this.isOnline}]`);
	}

	// Checks if the app is running in standalone mode
	private checkStandaloneMode(): void {
		if (window.matchMedia("(display-mode: standalone)").matches || (window.navigator as any).standalone) {
			this.isStandalone = true;
		}
	}

	// // Marks the setup as complete
	setSetupComplete(): void {
		localStorage.setItem("setupComplete", "true");
		this.progressCompleted = true;
		this.showOverlay = false;
	}

	// Starts prefetching assets and updates local storage
	startPrefetch(): void {
		this.ngxLoader.start();
		this.CacheAssetsService.fetchImages()
			.then(() => {
				const imageUrls = this.extractImageUrls(this.allData);
				const cachePromises = imageUrls.map((url) => {
					return this.cacheImages(url).then(() => {});
				});

				return Promise.all(cachePromises);
			})
			.then(() => {
				this.setSetupComplete();
				this.ngxLoader.stop();
				window.location.reload();
			})
			.catch((error) => {
				console.error("Error during prefetching assets", error);
				this.ngxLoader.stop();
			});
	}
	// Extracts image URLs from a nested object and prepends the backend URL
	private extractImageUrls(obj: any): string[] {
		const imageUrlPattern = /\/uploads\/[a-zA-Z0-9_-]+\.(png|jpg|jpeg|gif|bmp|webp|svg|pdf)/i;
		const urls: string[] = [];

		const recursiveExtract = (data: any) => {
			if (typeof data === "string" && imageUrlPattern.test(data)) {
				const fullUrl = `${this.backendUrl}${data}`;
				urls.push(fullUrl);
			} else if (Array.isArray(data)) {
				data.forEach((item) => recursiveExtract(item));
			} else if (typeof data === "object" && data !== null) {
				Object.keys(data).forEach((key) => {
					recursiveExtract(data[key]);
				});
			}
		};

		recursiveExtract(obj);
		return urls;
	}

	// Caches backend images after they have been extracted from extractImageUrls function
	private cacheImages(url: string): Promise<string> {
		return new Promise((resolve, reject) => {
			const cacheName = "backendImages";
			if ("caches" in window) {
				caches.open(cacheName).then((cache) => {
					fetch(url)
						.then((response) => response.blob())
						.then((blob) => {
							const blobUrl = URL.createObjectURL(blob);
							cache.put(url, new Response(blob));
							resolve(blobUrl);
						})
						.catch(reject);
				});
			} else {
				reject(new Error("Cache API not supported in this browser"));
			}
		});
	}

	// Filter the richText to get all the urls, then replace the startUrl with an empty string then save it in the array.
	extractUrls(text: string): string[] {
		const urlRegex = /(https?:\/\/[^\s]+)/g;
		const matches = text.match(urlRegex);
		return matches ? matches.map((url) => url.replace(/"/g, "")).map((url) => url.replace("https://pmi360.positif.ma/backend", "")) : [];
	}
}
