import { Component, OnInit, Output, ViewEncapsulation, EventEmitter, ViewChild, ElementRef } from "@angular/core";
import { FormGroup } from "@angular/forms";
import { Subject, takeUntil } from "rxjs";
import { ActivatedRoute, Router } from "@angular/router";
import { LasService } from "../core/services/las.service";
import { environment } from "src/environments/environment";
import { RoutingOrderService } from "src/app/core/services/routing-order.service";
import { ThemeService } from "../core/services/theme.service";
import { RouteOrderGuard } from "../core/guards/route.guard";
import { RoutePositionService } from "../core/services/route.position.service";
import { JourneyOfflineService } from "../core/services/journey.offline.service";

@Component({
	selector: "app-tutorial",
	templateUrl: "./tutorial.component.html",
	styleUrl: "tutorial.component.scss",
	encapsulation: ViewEncapsulation.None,
})
export class TutorialComponent implements OnInit {
	unsubscriber: Subject<any> = new Subject<any>();
	backendURL = environment.backendURL;
	ngStyle: any;
	form: FormGroup;

	tutorialRouteID: any;
	tutorialData: any;
	footerBtnText: string;
	footerText: string;
	campaignHeader: any;
	tuturialHeaderOpt: boolean;
	tuturialFooterOpt: boolean;

	// LAS Log
	lasData: any;
	stepName: string;
	tutorialLogVerfication: any;

	// CTA Theme
	colorCTA: string = "#fff";
	bgColorCTA: string = "teal";
	borderCTA: string = "none";

	backgroundImageUrl: string;

	// Final Step
	isFinalStep: boolean = false;

	// Routing config
	isAnyNextRoute: any;

	// Campaign CTA Text
	ctaNextText: string;
	ctaRetryText: string;

	constructor(
		private router: Router,
		private activatedRoute: ActivatedRoute,
		private lasService: LasService,
		private routingOrderService: RoutingOrderService,
		private themeService: ThemeService,
		private routeGuard: RouteOrderGuard,
		private routePositionService: RoutePositionService,
		private journeyOfflineService: JourneyOfflineService
	) {}
	@ViewChild("mainElement", { static: true }) mainElement: ElementRef;

	// --------------------------------------------------------------------------------------------
	// @ Lifecycle hooks
	// --------------------------------------------------------------------------------------------
	ngOnInit(): void {
		// Get the ID from the activated route and mark it as visited route within the id
		this.activatedRoute.paramMap.subscribe((params) => {
			this.tutorialRouteID = params.get("id") || "";

			if (this.tutorialRouteID) {
				this.routeGuard.markRouteAsVisited(`tutorial/${this.tutorialRouteID}`);
			} else {
				this.routeGuard.markRouteAsVisited("tutorial");
			}

			// Check if this route is the final step
			this.isFinalStep = this.routePositionService.getRouteOrderPosition(this.tutorialRouteID ? `tutorial/${this.tutorialRouteID}` : "tutorial");

			// Add step to the journey offline
			this.journeyOfflineService.addStep(this.tutorialRouteID ? `tutorial/${this.tutorialRouteID}` : "tutorial", this.isFinalStep);

			this.initializeLogVerfication();

			this.lasService.campaignSubject.pipe(takeUntil(this.unsubscriber)).subscribe((data: any) => {
				if (data != null) {
					this.campaignHeader = data.campaign.theme.header;
					this.footerText = data.campaign.theme.footer;

					this.ctaNextText = data.campaign.theme.cta?.ctaNextText;
					this.ctaRetryText = data.campaign.theme.cta?.ctaRetryText;

					this.tutorialData = data.campaign.steps.filter((e) => e.__component === "screen.tutorial")[this.tutorialRouteID ? this.tutorialRouteID - 1 : 0];
					this.tuturialHeaderOpt = this.tutorialData.isHeaderEnabled;
					this.tuturialFooterOpt = this.tutorialData.isFooterEnabled;

					// Set CTA text
					this.footerBtnText = this.tutorialData?.ctaText;
					this.isAnyNextRoute = this.routingOrderService.getNextRoute(this.tutorialRouteID ? `tutorial/${this.tutorialRouteID}` : "tutorial");
					// if (!this.isAnyNextRoute) {
					// 	this.footerBtnText = this.ctaRetryText;
					// } else {
					// 	if (this.tutorialData?.ctaText) {
					// 		this.footerBtnText = this.tutorialData?.ctaText;
					// 	} else {
					// 		this.footerBtnText = this.ctaNextText;
					// 	}
					// }

					// Log step
					const stepLebelFormated = this.removeSpacesBetweenWords(this.tutorialData.stepLabel);
					if (!this.tutorialLogVerfication[stepLebelFormated]) {
						this.stepName = this.tutorialData.stepLabel;
						this.lasService.lasSubject.pipe(takeUntil(this.unsubscriber)).subscribe((data) => {
							if (data != null) {
								this.lasData = data;
								this.logStep(this.lasData.journey, this.stepName, { ...(this.isFinalStep && { isFinalStep: true }) });
							}
						});

						this.updateLogVerfication(this.removeSpacesBetweenWords(this.stepName), "true");
					}

					// Set theme --------------------
					const { ngStyle, backgroundImageUrl } = this.themeService.getThemeStyles(data.campaign?.theme, this.tutorialData);
					this.ngStyle = ngStyle;
					this.backgroundImageUrl = backgroundImageUrl;
				}
			});
		});
	}

	ngOnDestroy(): void {
		this.unsubscriber.next(null);
		this.unsubscriber.complete();
	}
	// --------------------------------------------------------------------------------------------
	// @ Events
	// --------------------------------------------------------------------------------------------
	onFooterBtnClick() {
		// If is final step remove the log verfication
		if (this.isFinalStep) {
			localStorage.removeItem("logVerfication");
			localStorage.removeItem("visitedRoutes");
		}

		// Get the currentPath and check if is a dynamic route or regular route
		const currentPath = this.activatedRoute.snapshot.routeConfig.path;
		const actualPath = currentPath.includes(":id") ? currentPath.replace(":id", this.tutorialRouteID) : currentPath;

		this.routingOrderService.navigateToNext(actualPath);
	}

	onImageLoad(event) {
		event.target.classList.add("loaded");
	}

	// --------------------------------------------------------------------------------------------
	// @ Function
	// --------------------------------------------------------------------------------------------
	logStep(journeyId: number, stepLabel: string, additionalData = {}) {
		this.lasService
			.logStep(journeyId, stepLabel, additionalData)
			.pipe(takeUntil(this.unsubscriber))
			.subscribe({
				next: (response) => {
					console.log("Journey tutorial created:", response);
				},
				error: (error) => {
					console.error("Error creating journey:", error);
				},
			});
	}

	// Initialize LogVerfication & Update the component log
	private initializeLogVerfication() {
		const storedState = localStorage.getItem("logVerfication");
		this.tutorialLogVerfication = storedState ? JSON.parse(storedState) : {};
	}

	private updateLogVerfication(key: string, value: any) {
		this.tutorialLogVerfication[key] = value;
		localStorage.setItem("logVerfication", JSON.stringify(this.tutorialLogVerfication));
	}

	// Remove spaces from the text
	removeSpacesBetweenWords(text: any) {
		return text.replace(/(\S)\s+(\S)/g, "$1$2");
	}
}
